<template>
    <base-focus-trap
        id="artwork-overlay"
        class="flex items-center justify-center bg-black-80 transition-alpha"
        :class="{
          'invisible h-0 overflow-hidden': !open,
          'fixed inset-0 z-a1': open,
        }"
        :disabled="!open"
    >
        <!-- Dismiss Zone -->
        <div @mouseup="close" class="absolute inset-0 cursor-pointer"></div>

        <div class="max-w-7xl w-full relative z-1 text-white antialiased flex items-center my-15 md:mx-42">
            <!-- Content Goes here... -->
            <slot/>
        </div>

        <!-- Close Button -->
        <button v-if="addButton" @click="close" class="close-button">
            <base-svg icon="close"></base-svg>
        </button>
    </base-focus-trap>
</template>

<script>
    export default {
        props: ["open", "addButton"],
        created() {
            const escapeHandler = e => {
                if (e.key === "Escape" && this.open) {
                    this.close();
                }
            };

            document.addEventListener("keydown", escapeHandler);
            this.$once("hook:destroyed", () => {
                document.removeEventListener("keydown", escapeHandler);
            });
        },
        methods: {
            close() {
                this.$emit("close");
            }
        }
    };
</script>

<style type="scss" scoped>
    button {
        transition: color 0.2s ease;

        &:hover {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .close-button {
        @apply w-6 h-6 m-6 absolute top-0 right-0 text-white;
    }
</style>
