import focusTrap from 'focus-trap';
import { docReady, qsa } from "@js/libs/helpers";
import "@css/overlay.scss";

let isOpen = false;
let hamburger, header, trap, body, outer;

const setAttributes = () => {
	const s = isOpen ? 'true' : 'false';

	if ( isOpen ) {
		body.style.overflow = 'hidden';
	} else {
		body.style.overflow = '';
	}
	
	if ( hamburger ) {
		hamburger.setAttribute('aria-pressed', s );
	}

	if ( header ) {
		header.setAttribute('data-open', s );
	}
}

export const close = ( wasEvent ) => {
	isOpen = false;
	setAttributes();

	if ( !wasEvent && trap ) {
		trap.deactivate();
	}
}

export const open = () => {
	isOpen = true;
	setAttributes();
	if ( trap ) {
		trap.activate();
	}
}

export const toggle = () => {
	isOpen = !isOpen;
	setAttributes();

	if ( isOpen && trap ) {
		trap.activate();
	} else if ( !isOpen && trap ) {
		trap.deactivate();
	}
}

docReady( () => {

	body = document.body;
	hamburger = document.getElementById('hamburger');
	header = document.getElementById('site-header');
	outer = document.getElementById('site-header-outer');
	

	if (!hamburger || !header) return;

	trap = focusTrap( outer , {
		escapeDeactivates: true,
		clickOutsideDeactivates: false,
		onActivate: () => {
			// 
		},
		onDeactivate: () => {
			close( true );
		}
	});

	hamburger.addEventListener( 'click', e => {
		toggle();		
	});

	// we use js-ready to avoid some odd flicker caused by the transition rule
	hamburger.classList.add('js-ready');
	header.classList.add('js-ready');

});

window.h = {
	open,
	close,
	toggle
}

export default { open, close, toggle, trap };