
<div class="global-container">
  <div
    class="flex smonly:flex-col-reverse md:items-center my-8 md:my-16"
    :style="{
          minHeight: containerHeight
      }"
  >
    <div class="md:w-c2 transition-opacity">
      <div class="mt-4 md:mt-0 md:max-w-36">
        <div class="font-bold text-13" v-if="title">{{ title }}</div>
        <div class="text-13 font-light" v-if="year">{{ year }}</div>
        <div class="text-13 font-light" v-if="caption">{{ caption }}</div>
      </div>
    </div>

    <div class="smonly:flex smonly:items-center md:w-c8">
      <div v-if="this.cells.length > 1">
        <button @click="prev" class="flex md:hidden my-2 items-center font-mono text-12 hover:text-blue transition-all-colors">
          <span class="w-4 h-4 mr-3">
            <base-svg icon="arrow-left"></base-svg>
          </span>
        </button>
      </div>
      <div
        v-for="(img, i) in cells"
        class="w-full lazyload transition-opacity"
        :class="{
                'visible': index === i,
                'invisible hidden absolute opacity-0': index !== i
            }"
        :key="`cell-${i}`"
        ref="cells"
      >
        <img
          :src="img.src"
          @load="recalc"
          class="block h-auto"
          :class="{
            'w-full': isLandscape(i),
            'w-auto max-h-xl md:max-h-3xl mx-auto': !isLandscape(i)
            }"
        />
      </div>

      <div v-if="this.cells.length > 1">
        <button @click="next" class="flex md:hidden my-2 items-center font-mono text-12 hover:text-blue transition-all-colors">
          <span class="w-4 h-4 ml-3">
            <base-svg icon="arrow-right"></base-svg>
          </span>
        </button>
      </div>
    </div>

    <div v-if="this.cells.length > 1" class="hidden md:block md:w-c1 md:ml-auto">
      <div class="py-2 my-2 border-t border-b border-black-50">
        <button
          @click="next"
          class="flex my-2 pb-1 items-center font-mono text-12 hover:text-blue transition-all-colors"
        >
          <span class="w-3 h-3 mr-3">
            <base-svg icon="arrow-right"></base-svg>
          </span>
          <span>View Next</span>
        </button>

        <button
          @click="prev"
          class="flex my-2 pb-1 items-center font-mono text-12 hover:text-blue transition-all-colors"
        >
          <span class="w-3 h-3 mr-3">
            <base-svg icon="arrow-left"></base-svg>
          </span>
          <span>View Previous</span>
        </button>
      </div>
    </div>
  </div>
</div>
