import Vue from 'vue';
import Vuex from 'vuex';

import categories from "@js/components/store/categories"; 
import artworks from "@js/components/store/artworks"; 

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {
		muted : true,
	},
	getters: {
		muted: state => state.muted,
	},
	mutations: {
		toggleMuted(state) {
			Vue.set(state, 'muted', ! state.muted);
		}
	},
	modules: {
		categories,
		artworks
	},
});

window.store = store;





export default { store };