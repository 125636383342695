<template>
    <Carousel :cells="cells">
    </Carousel>
</template>

<script>
    import Carousel from "@js/components/parts/Carousel.vue";

    export default {
        props: ["cells"],
        components: {
            Carousel
        },
    };
</script>

<style type="scss" scoped>

</style>
