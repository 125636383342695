<template>
    <Overlay @close="close" :open="open">
        <ArtworkCarousel :open="open" :cells="cells">
            <template v-slot:ui>
                <button
                    @click="$emit('close')"
                    class="close-button w-6 h-6 absolute top-0 right-0 text-white">
                    <base-svg icon="close"></base-svg>
                </button>
            </template>
        </ArtworkCarousel>
    </Overlay>
</template>

<script>
    import ArtworkCarousel from "@js/components/parts/ArtworkCarousel.vue";
    import Overlay from "@js/components/parts/Overlay.vue";

    export default {
        props: ["open", "cells", "trigger"],
        components: {
            Overlay,
            ArtworkCarousel
        },
        methods: {
            close() {
                this.$emit("close");
            }
        }
    };
</script>

<style type="scss" scoped>
    .close-button {
        top: -40px;
        right: -70px;
    }
</style>
