import Vue from "vue";
import InlineCarousel from "@js/components/apps/InlineCarousel.vue";

import { qsa, docReady } from "@js/libs/helpers";

docReady(() => {

    qsa('.vue-carousel', (el, i) => {
        const fns = [];
        const props = {
            cells: [],
        };

        const slides = el.querySelectorAll('[data-artwork-carousel]');
        qsa(slides, (image, i) => {

            const src = image.getAttribute('data-artwork-carousel');
            const isLandscape = image.getAttribute('data-isLandscape');
            const title = image.getAttribute('data-title');
            const year = image.getAttribute('data-year');
            const caption = image.getAttribute('data-caption');

            if ( src ) {
                props.cells.push({ i, src, isLandscape, title, year, caption });
            }
        });

        console.log(props);

        if (el && props.cells.length) {


                const vm = new Vue({
                    el,
                    data: props,
                    render: h => h(InlineCarousel, {
                        props,
                    })
                });


            fns.forEach(fn => fn());
            window.addEventListener('resize.end', () => fns.forEach(fn => fn()));

        }

    });
});