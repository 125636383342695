<template>
    <div class="flex items-center">
        <button @click="prev" class="w-20 h-20 p-6 absolute left-0 -ml-40">
            <base-svg icon="arrow-left"></base-svg>
        </button>

        <div v-if="caption" class="text-15 leading-tight w-44 mr-20 border-t pt-5">{{ caption }}
        <span v-if="year">
            {{ year }}
        </span>
        </div>

        <div v-for="(img, i) in cells"
             class="w-full h-auto lazyload transition-opacity"
             :class="{
                 'visible': index === i,
                 'invisible absolute opacity-0': index !== i
             }"
             :key="`cell-${i}`"
        >
            <img class="w-full h-auto" :src="img.src"/>
        </div>

        <slot name="ui"/>

        <button @click="next" class="w-20 h-20 p-6 absolute right-0 -mr-40">
            <base-svg icon="arrow-right"></base-svg>
        </button>
    </div>
</template>

<script>
    export default {
        props: ["cells", "open"],

        data() {
            return {
                index: 0
            };
        },
        computed: {
            caption() {
                return this.cells[this.index].caption || "";
            },
            year() {
                return this.cells[this.index].year || "";
            }
        },
        watch: {
            open: {
                handler() {
                    if (this.trigger) {
                        this.index = parseInt(this.trigger);
                    }
                }
            }
        },
        methods: {
            updateCarousel(i) {
                if (typeof i === "number") {
                    this.index = i;
                }
            },
            next() {
                let newIndex = this.index + 1;

                if (newIndex >= this.cells.length) {
                    newIndex = 0;
                }
                this.index = newIndex;
            },
            prev() {
                let newIndex = this.index - 1;

                if (newIndex < 0) {
                    newIndex = this.cells.length - 1;
                }
                this.index = newIndex;
            }
        }
    };
</script>

<style type="scss" scoped>

</style>
