import standardCarousel from "@js/libs/standard-carousel";
import { docReady, qsa } from "@js/libs/helpers";
import { MediaQueries } from "@js/libs/media-queries";

const getData = (el, attr) => {
    return el.getAttribute(attr);
};

docReady( () => {
    const initFns = [];

    qsa('[data-carousel="hero"]', el => {
        let flkty;
        const breakpoint = getData(el, 'data-breakpoint');
        // Scrape flickity options from data attr.
        const dataOptionsText = getData(el, 'data-config');
        let options = false;

        let previousButton = el.querySelector('.button--previous');
        let nextButton = el.querySelector('.button--next');

        if ( dataOptionsText ) {
            options = JSON.parse( dataOptionsText );
        }

        const init = () => {
            if ( flkty && !breakpoint ) {
                // Flkty already initialised, no breakpoint, no need to restore, just return.
                return;
            }

            if ( flkty ) {
                // if init is happening again, kill a flkty
                // to rebuild.
                flkty.destroy();
            }

            if ( breakpoint && !media.matches( breakpoint ) ) {
                // breakpoint doesn't match, and since flickity was destroyed
                // this means we can exit with no flickity initialised.
                return;
            }

            // Flickity is now initialised with the options.
            flkty = standardCarousel(el, '[data-slide]', options);

            previousButton.addEventListener( 'click', e => {
                flkty.previous();
            });

            nextButton.addEventListener( 'click', e => {
                flkty.next();
            });
        };

        // Store all our initialisation functions...
        initFns.push( init );
    });

    // ...to be executed in one go...
    const initAll = () => {
        initFns.forEach( fn => fn() );
    };

    // ...once on load
    initAll();

    // ...and altogether on a window resize.
    window.addEventListener('resize', initAll );
});