
<div class="flex items-center">
    <button @click="prev" class="w-20 h-20 p-6 absolute left-0 -ml-40">
        <base-svg icon="arrow-left"></base-svg>
    </button>

    <div v-if="caption" class="text-15 leading-tight w-44 mr-20 border-t pt-5">{{ caption }}
    <span v-if="year">
        {{ year }}
    </span>
    </div>

    <div v-for="(img, i) in cells"
         class="w-full h-auto lazyload transition-opacity"
         :class="{
             'visible': index === i,
             'invisible absolute opacity-0': index !== i
         }"
         :key="`cell-${i}`"
    >
        <img class="w-full h-auto" :src="img.src"/>
    </div>

    <slot name="ui"/>

    <button @click="next" class="w-20 h-20 p-6 absolute right-0 -mr-40">
        <base-svg icon="arrow-right"></base-svg>
    </button>
</div>
