import Flickity from 'flickity-fade';
import "flickity/css/flickity.css";
import "flickity-fade/flickity-fade.css";
import "@css/flickity-overrides.scss";

export const standardCarousel = function (carousel, selector, options = {}) {

	const mergedOptions = Object.assign( {
		cellSelector: selector,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: false,
		setGallerySize: false,
		autoPlay: 3000,
		pauseAutoPlayOnHover: true,
		fade: true,
		lazyLoad: 2,
		// selectedAttraction: 0.006,
		// friction: 0.15,
		arrowShape: {
			x0: 20,
			x1: 50, y1: 20,
			x2: 80, y2: 40,
			x3: 80
		},
	}, options );

	return new Flickity(carousel, mergedOptions);
};

export default standardCarousel;
