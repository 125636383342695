
import Vue from "vue";
import { get, registerBaseUrl } from "@js/components/store/_ajax";

// Maybe do from craft?
registerBaseUrl('/api');

export default {
	namespaced:true,
	state: {
		loading: false,
		pushedUrl: '',
		results: [],
	},
	mutations: {	
		loadResults( state, results ) {
			Vue.set( state, 'results', results );
		},
		setUrl( state, url ) {
			state.pushedUrl = url;
		}
	},
	actions: {

		preload({ commit }, { results, url } ) {
			commit('setUrl', url );
			commit('loadResults', results );
		},
		
		async load({ state, commit }, url ) {

			state.loading = true;
			const { success, results } = await get( url )
				.catch( e => console.error(e) )
				.finally( () => state.loading = false );

			if ( success ) {
				commit('loadResults', results );
			}
			
		}
		
	}
};