import { store } from "@js/vue-setup/register-store";
import Vue from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';

const __NAMESPACE__ = '__VUEX_CATEGORIES__';

const vm = new Vue({
	store,
	computed: {
		...mapState({
			active: state => state.categories.active,
			pushedUrl: state => state.artworks.pushedUrl,
		})
	},
	data() {
		return {
			firstTime: true,
			stateUrl: '',
		}
	},
	watch: {
		active( newCategory, oldCategory ) {

			if ( newCategory.url !== this.stateUrl ) {
				this.pushState( newCategory );
			}

			this.load( `artworks/${newCategory.id}` );

		},
	},
	methods: {
	
		pushState( category ) {
			this.setUrl( category.url );
			this.stateUrl = category.url;
			history.pushState( { namespace: __NAMESPACE__, ...category }, category.title, category.url );
		},
		replaceState( category ) {
			this.setUrl( category.url );
			history.replaceState( { namespace: __NAMESPACE__, ...category }, category.title, category.url );
		},
		...mapActions({
			load: 'artworks/load',
			choose: 'categories/choose',
			activateAll: 'categories/activateAll'
		}),
		...mapMutations({
			setUrl: 'artworks/setUrl'
		})
	},
	created() {
		
		
		if ( this.active ) {
			console.log( "Replace State: ", this.active );
			this.replaceState(this.active);
			this.$store.commit('artworks/setUrl', this.active.url );
		} else {
			history.replaceState( { namespace: __NAMESPACE__, reset: true }, document.title, window.location.href );
		}

		window.onpopstate = e => {
			if ( !e || !e.state ) {
				console.log( e );
				return;
			}

			const { namespace, slug, group, id, reset } = e.state;			
			
			this.stateUrl = window.location.href;
			
			if (namespace !== __NAMESPACE__ ) {
				return;
			}			
			
			if ( reset ) {
				window.location.href = window.location.href;
				return;
			}
	
			this.choose({
				slug, group
			});
			
			
		};
	},
	render: () => false
});



