
<base-focus-trap
    id="artwork-overlay"
    class="flex items-center justify-center bg-black-80 transition-alpha"
    :class="{
      'invisible h-0 overflow-hidden': !open,
      'fixed inset-0 z-a1': open,
    }"
    :disabled="!open"
>
    <!-- Dismiss Zone -->
    <div @mouseup="close" class="absolute inset-0 cursor-pointer"></div>

    <div class="max-w-7xl w-full relative z-1 text-white antialiased flex items-center my-15 md:mx-42">
        <!-- Content Goes here... -->
        <slot/>
    </div>

    <!-- Close Button -->
    <button v-if="addButton" @click="close" class="close-button">
        <base-svg icon="close"></base-svg>
    </button>
</base-focus-trap>
