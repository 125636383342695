import 'what-input';

/* CSS */
import "@css/app.scss";

// Polyfills
import "@js/libs/polyfills";

// Our App
import "@js/parts/lazyloading";
import "@js/parts/hamburger";
import { initHeader } from "@js/parts/sticky-header";
import "@js/vue-setup";

import { Exposure } from "@js/libs/exposure";

import "@js/parts/artwork-overlay";
import "@js/parts/artwork-carousel";
import "@js/parts/hero-carousel";
import "@js/parts/inline-carousel";
import "@js/parts/image-zoom";
import "@js/parts/stories";

const getScrollsnap = () => import("@js/parts/scrollsnap");

// helpers to initiate things dynamically
import { qsa, docReady } from "@js/libs/helpers";

initHeader();

docReady( () => {
	const carousels = document.querySelectorAll('[data-scrollsnap]');

	if ( carousels.length ) {
		getScrollsnap().then( scrollsnap => {
			carousels.forEach( c => scrollsnap.default(c) );
		});
	}

	qsa('[data-exposure]', el => {
		const height = el.getAttribute('data-height') || 300;

		new Exposure( el, {
			maxHeight: height,
		} );
	})
});

