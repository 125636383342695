
<Overlay @close="close" :open="open">
    <ArtworkCarousel :open="open" :cells="cells">
        <template v-slot:ui>
            <button
                @click="$emit('close')"
                class="close-button w-6 h-6 absolute top-0 right-0 text-white">
                <base-svg icon="close"></base-svg>
            </button>
        </template>
    </ArtworkCarousel>
</Overlay>
