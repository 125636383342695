import Vue from 'vue';
import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';

// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.
const requireComponent = import.meta.glob('@js/components/base/*.vue');

for (const path in requireComponent) {
	requireComponent[path]().then((comp) => {
		const componentName = upperFirst(
			camelCase(
				path
				// Remove the "./_" from the beginning
				.replace(/^\.\/_/, '')
				// Remove the file extension from the end
				.replace(/\.\w+$/, '')
				// Remove leading slashes
				.split("/").pop()
			)
		);

		// Globally register the component
		Vue.component(componentName, comp.default || comp);
	});
}
