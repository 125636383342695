import axios from "axios";

const config = {
	responseType: 'json',
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/x-www-form-urlencoded'
	}
};

const csrfUrl = '/csrf.json';
let baseUrl = '';

export const registerBaseUrl = _baseUrl => {
	baseUrl = _baseUrl;
};

export const getCsrf = async function () {
	
	const res = await axios.get(state.csrfUrl).catch( e => {
		console.error(e)
	});

	if ( !res ) {
		return state.csrfUrl;
	}

	const { data: response } = res;
	const { csrfName, csrfValue } = response;
	return {
		name: csrfName, value: csrfValue
	};
};

export const post = async function ( url , _data, extra = {} ) {
	const params = new URLSearchParams(_data);
	
	const csrf = await getCsrf(); 
	
	params.append( csrf.name, csrf.value );
	return axios.post( `${baseUrl}/${url}` , params, {
		...config,
		...extra,
	});
};

export const get = async function ( url , _data, extra = {} ) {
	const { data } = await axios.get( `${baseUrl}/${url}` , {
		...extra,
		params: _data
	}, config);

	return data;
};

export default { get, post, registerBaseUrl, getCsrf };
