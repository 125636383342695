import Vue from "vue";

export default {
	namespaced:true,
	state: {

		allCategory: {

		},

		active: false,
		expanded: false,
		groups: {

		},
	},
	mutations: {
		clearActive( state ) {		
			Vue.set( state, 'active', false );
		},

		setAllUrl( state, { group, slug, groupTitle, url, id } ) {
			Vue.set(state, 'allCategory', { group, slug, groupTitle, url, id } );
		},

		add( state, { 
			group,
			url,
			groupTitle,
			categories,
		} ) {

			if ( !group || !groupTitle || !categories ) {
				console.warn("Missing Data", {
					groupTitle,group,categories
				});
				return false;
			}

			const sanitisedCategories = [];

			if ( !Array.isArray( categories ) ) {
				console.warn("Categories was not array", categories );
				return false;
			} else {

				categories.forEach( ({ url, title, slug, id }) => {
					if ( url && title && slug && id ) {
						sanitisedCategories.push({
							url, title, slug, group, id: parseInt(id)
						});
					} else {
						console.warn( "Skipped a category: ", { url, title, slug });
					}
				})
			}

			if ( state.groups[ group ] ) {
				console.warn(`Replacing ${group} Category`);
			}

			state.groups[ group ] = { group, groupTitle, categories: sanitisedCategories };
			return true;
		},
		
		activate( state, newCategory ) {

			if ( state.active.url === newCategory.url ) {	
				return;
			}
			
			Vue.set( state, 'active', newCategory );			

		},

		toggle( state, group ) {
			if ( state.expanded === group ) {
				state.expanded = false;
			} else {
				state.expanded = group;
			}
		}
		
	},
	actions: {
		add( { commit }, data ) {
			commit( 'add', data );
		},

		setAllUrl( { commit }, { group, slug, groupTitle, url, id }) {
			commit( 'setAllUrl', { group, slug, groupTitle, url, id });
		},

		activateAll( { commit, state } ) {
			commit('activate', state.allCategory );
		},
		
		choose( { commit, state }, { group: groupHandle, slug: categorySlug } ) {

			if ( groupHandle === state.allCategory.group && categorySlug === state.allCategory.slug ) {
				return commit('activate', state.allCategory );
			}

			const group = state.groups[ groupHandle ];
			const categoriesFound = group.categories.filter( ({ slug: _slug }) => categorySlug === _slug );

			if ( categoriesFound.length === 0 ) {
				console.warn(`${categorySlug} not found in ${groupHandle}`);
				return false;
			}

			const category = categoriesFound[0];

			commit('activate', category );
			
		
		}		
	}
};