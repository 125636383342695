import { docReady, qsa } from "@js/libs/helpers";
import { media } from "@js/parts/media";
import "@css/stories.scss";
import Player from "@vimeo/player";

import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Linear, Power1 } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

docReady(() => {
    const stories = document.querySelector('.stories');

    if (!stories){
        return;
    }

    const controller = new ScrollMagic.Controller();

    // 01 - Intro (Background Fadeout)
    const fadeOutTrigger = document.querySelector('[data-scroll="fadeOutTrigger"]');
    const background = document.querySelector('[data-scroll="fadeOut"]');
    const fadeOut = new TweenMax.to(background, 1, { autoAlpha: 0.33, ease: Linear.easeIn });

    let introScene = new ScrollMagic.Scene({
        duration: '100%',
        triggerElement: fadeOutTrigger,
        triggerHook: 'onCenter',
    })
        .setTween(fadeOut)
        // .addIndicators({ name: '01 - Intro (33%)' })
        .addTo(controller);

    // 02 - 3 Perspectives (Expand Up Animation)
    const expandTrigger = document.querySelector('[data-scroll="expandTrigger"]');
    const expandUp = document.querySelectorAll('[data-scroll="expandUp"]')
    let expandTimeline = new TimelineMax().staggerFrom(expandUp, 1, { bottom: '0', ease: Expo.easeOut }, 0.12); //seconds between each tween's start time.

    const expandScene = new ScrollMagic.Scene({
        triggerElement: expandTrigger,
        triggerHook: 'onCenter',
    })
        .setTween(expandTimeline.totalDuration(3.5))
        // .addIndicators({ name: '02 - 3 Perspectives' })
        .addTo(controller);

    //  03 - Roads to Mecca (Fade Background Video)
    const fadeOutVideoTrigger = document.querySelector('[data-scroll="fadeOutVideoTrigger"]');
    const videoBackground = document.querySelector('[data-scroll="fadeOutVideo"]');
    const fadeOutVideo = TweenMax.to(videoBackground, 1, { autoAlpha: 0.5, ease: Linear.easeIn });

    let roadsScene = new ScrollMagic.Scene({
        duration: '100%',
        triggerElement: fadeOutVideoTrigger,
        triggerHook: 'onEnter',
    })
        .setTween(fadeOutVideo)
        // .addIndicators({ name: '03 - Roads to Mecca (Fade)' })
        .addTo(controller);

    // 05 - Viewfinders (Fade Images)
    let viewfinderElements = document.querySelectorAll('[data-scroll="viewfinderFade"]');
    let viewfinderTriggers = document.querySelectorAll('[data-scroll="viewfinderTrigger"]');
    let firstViewfinderElement = viewfinderElements[0];

    let viewfinderFadeTimeline = new TimelineMax();
    viewfinderFadeTimeline.set(firstViewfinderElement, {autoAlpha: 1});

    qsa(viewfinderTriggers, (el, i) => {
        let currentEl = viewfinderElements[i];
        let nextEl = viewfinderElements[i+1];

        let viewfindersScene = new ScrollMagic.Scene({
            triggerElement: el,
            triggerHook: 'onCenter',
        })
            // .setTween(viewfinderFadeTimeline)
            // .addIndicators()
            .addTo(controller);

        viewfindersScene.on('enter', (e) => {
            viewfinderFadeTimeline.to(currentEl, 0.25, {autoAlpha: 0, ease: Linear.easeInOut})
                .to(nextEl, 0.25, {autoAlpha: 1, ease: Linear.easeInOut});
        });

        viewfindersScene.on('leave', (e) => {
            viewfinderFadeTimeline.to(currentEl, 0.25, {autoAlpha: 1, ease: Linear.easeInOut})
                .to(nextEl, 0.25, {autoAlpha: 0, ease: Linear.easeInOut});
        })
    });

    // 06 - Commercial City (Fade Images)
    let commercialCityElements = document.querySelectorAll('[data-scroll="commercialCityFade"]');
    let commercialCityTriggers = document.querySelectorAll('[data-scroll="commercialCityTrigger"]');
    let firstCommercialCityElement = commercialCityElements[0];

    let commercialCityFadeTimeline = new TimelineMax();
    commercialCityFadeTimeline.set(firstCommercialCityElement, {autoAlpha: 1});

    qsa(commercialCityTriggers, (el, i) => {
       let currentEl = commercialCityElements[i];
       let nextEl = commercialCityElements[i+1];

       let commercialCityScene = new ScrollMagic.Scene({
          triggerElement: el,
          triggerHook: 0.7,
       })
           // .addIndicators()
           .addTo(controller);

       commercialCityScene.on('enter', (e) => {
           commercialCityFadeTimeline.to(currentEl, 0.25, {autoAlpha: 0, ease: Linear.easeInOut})
               .to(nextEl, 0.25, {autoAlpha: 1, ease: Linear.easeInOut});
       });

       commercialCityScene.on('leave', (e) => {
           commercialCityFadeTimeline.to(currentEl, 0.25, {autoAlpha: 1, ease: Linear.easeInOut})
               .to(nextEl, 0.25, {autoAlpha: 0, ease: Linear.easeInOut});
       });
    });

    // 06 - Surveillance (Control playback via scroll)
    const videoScrollTrigger = document.querySelector('[data-scroll="videoScrollTrigger"]');
    const videoEl = videoScrollTrigger.querySelector('video');

    let scrollPosition = 0;
    let lastScrollPosition;

    const videoScrollScene = new ScrollMagic.Scene({
        triggerElement: videoScrollTrigger,
        triggerHook: 'onCenter'
    });

    const startScrollAnimation = () => {
        videoScrollScene
            .duration(videoScrollTrigger.clientHeight)
            // .addIndicators()
            .addTo(controller)
            .on('progress', (e) => {
                scrollPosition = e.progress;
            });

        setInterval(() => {
            if (lastScrollPosition === scrollPosition) {
                return;
            }

            requestAnimationFrame(() => {
                videoEl.currentTime = videoEl.duration * scrollPosition.toFixed(3);
                videoEl.currentTime.toFixed(2);
                videoEl.pause();
                lastScrollPosition = scrollPosition;
                console.log((videoEl.currentTime).toFixed(2), scrollPosition.toFixed(3));
            });
        }, 50);
    };

    const preloadVideo = (video, callback) => {
        const ready = () => {
            video.removeEventListener('canplaythrough', ready);

            video.pause();
            let i = setInterval(function() {
                // console.log(video.readyState);
                if (video.readyState > 3) {
                    clearInterval(i);
                    video.currentTime = 0;
                    callback();
                }
            }, 33.3);
        };
        video.addEventListener('canplaythrough', ready, false);
        video.play();
    };

    preloadVideo(videoEl, startScrollAnimation);

    //  07 - Ritual City (Fade Background Video)
    const fadeOutVideoTrigger2 = document.querySelector('[data-scroll="fadeOutVideoTrigger2"]');
    const videoBackground2 = document.querySelector('[data-scroll="fadeOutVideo3"]');
    const fadeOutVideo2 = TweenMax.to(videoBackground2, 1, { autoAlpha: 0.3, ease: Linear.easeIn });

    let stonesScene = new ScrollMagic.Scene({
        triggerElement: fadeOutVideoTrigger2,
        triggerHook: 'onEnter',
        duration: '100%',
    })
        .setTween(fadeOutVideo2)
        // .addIndicators({ name: '07 - Roads to Mecca (Fade Video)' })
        .addTo(controller);


    // 08 - A Prayer for a Real City (Background Fadeout)
    const fadeOutTrigger2 = document.querySelector('[data-scroll="fadeOutTrigger2"]');
    const background2 = document.querySelector('[data-scroll="fadeOut2"]');
    const fadeOut2 = new TweenMax.to(background2, 1, { autoAlpha: 0.1, ease: Linear.easeIn });

    let realCityScene = new ScrollMagic.Scene({
        duration: '100%',
        triggerElement: fadeOutTrigger2,
        triggerHook: 'onEnter',
    })
        .setTween(fadeOut2)
        // .addIndicators({ name: '08 - A Prayer for a Real City' })
        .addTo(controller);

    // Progress Bar indicator
    let progressBar = () => {
        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let scrolled = (winScroll / height) * 100;
        document.getElementById('scrollBar').style.height = `${scrolled}%`;
    };
    window.onscroll = () => {
        progressBar()
    };

    // Generic Fade In
    const fadeIn = document.querySelectorAll('[data-scroll="fadeIn"]');

    qsa(fadeIn, (el, i) => {
        let timeline = new TimelineMax();
        timeline.from(el, 0.5, {autoAlpha: 0, ease: Linear.easeIn});

        let scene = new ScrollMagic.Scene({
            triggerElement: el,
            triggerHook: 0.75,
        })
            .setTween(timeline)
            // .addIndicators()
            .addTo(controller);
    });

    // Generic Fade In Staggered (Side by side elements)
    const fadeInStaggerTrigger = document.querySelectorAll('[data-scroll="fadeInStaggerTrigger"]');

    qsa(fadeInStaggerTrigger, (el, i) => {
        let fadeInStagger = el.querySelectorAll('[data-scroll="fadeInStagger"]');

        let timeline = new TimelineMax();
        timeline.staggerFrom(fadeInStagger, 0.5, {autoAlpha: 0, ease: Linear.easeIn}, 0.25);

        let scene = new ScrollMagic.Scene({
            triggerElement: el,
            triggerHook: 0.75,
        })
            .setTween(timeline)
            // .addIndicators()
            .addTo(controller);
    });

    // Vimeo Player Create / Destroy
    let vimeoWrapper = document.querySelectorAll('[data-vimeo]');

    qsa(vimeoWrapper, (el, i) => {
        // Get Vimeo ID
        let vimeoId = el.getAttribute('data-vimeo');

        if (vimeoId) {
            // Default options
            let options = {
                id: vimeoId,
                autoplay: true,
                background: true,
                loop: true,
                muted: true,
                dnt: true,
                controls: false,
            };

            // Let the element override options by setting data-vimeo-options="" attribute
            try {
                let vimeoOptions = JSON.parse(el.getAttribute('data-vimeo-options'));
                if(vimeoOptions) {
                    Object.assign(options, vimeoOptions);
                }
            } catch(e) {}

            let triggerWrapper = el.closest('[data-vimeo-trigger="wrapper"]');
            if (triggerWrapper) {
                let topTrigger = triggerWrapper.querySelector('[data-vimeo-trigger="top"]');

                if (topTrigger) {
                    let enterScene = new ScrollMagic.Scene({
                        triggerElement: topTrigger,
                        triggerHook: 'onEnter',
                        duration: 1,
                        offset: -200,
                    })
                        // .addIndicators()
                        .addTo(controller)
                        .on('enter leave', (e) => {
                            // If the scene coming into view
                            // and scrolling down
                            // and there is current no Vimeo iFrame player
                            if (e.type == 'enter' && e.scrollDirection == 'FORWARD' && !el.querySelector('iframe')) {
                                // Create a player with the Vimeo ID
                                let player = new Player(el, options);
                                // console.log(`Scrolled: ${e.scrollDirection}, create player enter inside`);

                                // Fade in Vimeo iFrame player only when the video has started to play
                                // Otherwise the player with appear suddenly
                                // The iFrame is set to opacity: 0 in CSS
                                player.on('playing', function(data) {
                                    let iframe = el.querySelector('iframe');
                                    let timeline = new TimelineMax();
                                    timeline.to(iframe, 0.2, {autoAlpha: 1, ease: Linear.easeIn});
                                });
                            // If is scene leaving view
                            // and scrolling up
                            // and there is currently a Vimeo iFrame player
                            } else if (e.type == 'leave' && e.scrollDirection == 'REVERSE' && el.querySelector('iframe')) {
                                // Destroy the iFrame player (remove from DOM)
                                let player = new Player(el);
                                player.destroy().then(() => {
                                    // console.log(`Scrolled: ${e.scrollDirection} the player was destroyed enter outside`);
                                }).catch((error) => {
                                    // console.log('an error occurred')
                                })
                            }
                        });
                }

                let bottomTrigger = triggerWrapper.querySelector('[data-vimeo-trigger="bottom"]');
                if (bottomTrigger) {
                    let leaveScene = new ScrollMagic.Scene({
                        triggerElement: bottomTrigger,
                        triggerHook: 'onLeave',
                        duration: 1,
                        offset: 200,
                    })
                        // .addIndicators()
                        .addTo(controller)
                        .on('enter leave', (e) => {
                            // If the scene coming into view
                            // and scrolling up
                            // and there is current no Vimeo iFrame player
                            if (e.type == 'enter' && e.scrollDirection == 'REVERSE' && !el.querySelector('iframe')) {
                                // Create a player with the Vimeo ID
                                let player = new Player(el, options);
                                // console.log(`Scrolled: ${e.scrollDirection} create player leave inside`);

                                // Fade in Vimeo iFrame player only when the video has started to play
                                // Otherwise the player with appear suddenly
                                // The iFrame is set to opacity: 0 in CSS
                                player.on('playing', function(data) {
                                    let iframe = el.querySelector('iframe');
                                    let timeline = new TimelineMax();
                                    timeline.to(iframe, 0.2, {autoAlpha: 1, ease: Linear.easeIn});
                                });
                            // If is scene leaving view
                            // and scrolling down
                            // and there is currently a Vimeo iFrame player
                            } else if (e.type == 'leave' && e.scrollDirection == 'FORWARD' && el.querySelector('iframe')){
                                // Destroy the iFrame player (remove from DOM)
                                let player = new Player(el);
                                player.destroy().then(() => {
                                    // console.log(`Scrolled: ${e.scrollDirection} the player was destroyed leave outside`);
                                }).catch((error) => {
                                    // console.log('an error occurred')
                                })
                            }
                        });
                }
            }
        }
    })

    // Chapter / Section title
    const progressWrapper = document.querySelector('#progressWrapper');
    let fadeInTimeline = new TimelineMax();
    fadeInTimeline.from(progressWrapper, 0.4, {autoAlpha: 0, ease: Linear.easeInOut});

    let fadeProgress = new ScrollMagic.Scene({
        triggerElement: fadeOutTrigger,
        triggerHook: 'onCenter',
    })
        .setTween(fadeInTimeline)
        // .addIndicators()
        .addTo(controller);

    const sectionTitleContainer = document.querySelector('#title');
    const sectionTitles = document.querySelectorAll('[data-title]');
    const sectionTitle = Array.from(sectionTitles);

    qsa(sectionTitles, (el, i) => {
        let titleScene = new ScrollMagic.Scene({
            triggerElement: el,
            triggerHook: 'onCenter',
        })
            // .setTween()
            // .addIndicators()
            .addTo(controller)
            .on('enter leave', (e) => {
                if (e.type === 'enter') {
                    sectionTitleContainer.innerHTML = sectionTitle[i].innerHTML;
                } else if (e.type === 'leave' && sectionTitle.indexOf(el) > 0) {
                    sectionTitleContainer.innerHTML = sectionTitle[i-1].innerHTML;
                }
            });
    });

    // Resize Calc
    let isLarge = media.matches('md');
    const calc = () => {
        if (media.matches('md')) {
            isLarge = true;
            console.log(`Large screen`);
            // controller.enabled(true);

            // 04 - The only constant (Horizontal Scroll)
            const horizontalTrigger = document.querySelector('[data-scroll="horizontalTrigger"]');
            const horizontalWrapper = document.querySelector('[data-scroll="horizontalWrapper"]');
            const horizontalSlides = horizontalTrigger.querySelectorAll('[data-scroll="horizontalSlide"]');
            const horizontalAmount = horizontalSlides.length;
            const horizontalMovement = TweenMax.to(horizontalWrapper, 1, { x: `-${(100 / horizontalAmount) * (horizontalAmount - 1.17) }%`});

            let horizontalScene = new ScrollMagic.Scene({
                duration: `${horizontalAmount * 100}%`,
                triggerElement: horizontalTrigger,
                triggerHook: 'onLeave',
            })
                .setPin(horizontalTrigger)
                .setTween(horizontalMovement)
                // .addIndicators({ name: '04 - The only constant' })
                .addTo(controller);

            // 07 - Ritual City (Intro)
            const scaleTrigger = document.querySelector('[data-scroll="scaleTrigger"]');
            const scaleImage = document.querySelector('[data-scroll="scaleImage"]');
            const scrollDownElement = document.querySelector('[data-scroll="scrollDown"]');

            const scaleBackground = new TimelineMax();
            scaleBackground.fromTo(scaleImage, 1, {scale: '4.7'}, {scale: '1'})

            let ritualCityScene = new ScrollMagic.Scene({
                triggerElement: scaleTrigger,
                triggerHook: 'onLeave',
                duration: '100%',
            })
                .setPin(scaleTrigger)
                // .addIndicators({name: 'pin'})
                .addTo(controller);

            let scaleDownScene = new ScrollMagic.Scene({
                triggerElement: scaleTrigger,
                triggerHook: 'onLeave',
                duration: '100%',
            })
                .setTween(scaleBackground)
                // .addIndicators()
                .addTo(controller);

            // Generic Pin Spacer
            const pinSpacer = document.querySelectorAll('[data-scroll="pinSpacer"]');

            qsa(pinSpacer, (el) => {
                let scene = new ScrollMagic.Scene({
                    triggerElement: el,
                    triggerHook: 'onEnter',
                    duration: '100%'
                })
                    .setPin(el)
                    // .addIndicators()
                    .addTo(controller);
            })
        } else {
            isLarge = false;
            console.log(`Mobile screen`);
            // controller.enabled(false);
            // TweenMax.killAll();
            //
            // let pinSpacer = document.querySelectorAll('.scrollmagic-pin-spacer');
            //
            // qsa(pinSpacer, (el) => {
            //     console.log(el.getAttribute('style'));
            //     el.style.paddingTop = '0';
            // })

            // console.log(introScene);
            // introScene = introScene.destroy(true);
            // introScene = null;
        }
        // controller.enabled(isLarge);
        console.log(controller.enabled())
    };
    calc();
    window.addEventListener('resize', calc);
});
