import Vue from "vue";
import OverlayCarousel from "@js/components/apps/OverlayCarousel.vue";

import { qsa, docReady } from "@js/libs/helpers";

const getTarget = ( image ) => {
    let src = image.getAttribute('data-overlay-gallery');
    if ( !src && image.tagName === "IMG" ) {
        src = image.src;
    }

    if ( src ) {
        return src;
    }

    return false;
};

docReady(() => {

    const fns = [];
    const vueOverlay = document.getElementById('vue-overlay');
    const props = {
        open: false,
        trigger: false,
        cells: [],
    };

    qsa('[data-overlay-gallery]', (image,i) => {

        const src = getTarget(image);
        const caption = image.getAttribute('data-caption');

        if ( src ) {
            props.cells.push({ i, src, caption });
        }

    });

    if ( vueOverlay && props.cells.length ) {

        const vm = new Vue({
            el: vueOverlay,
            data: props,
            render: h => h(OverlayCarousel, {
                props,
                on: {
                    close() {
                        props.open = false;
                    }
                }
            }),
        });

        qsa('[data-trigger-zoom]', button => {
            button.addEventListener('click', e => {
                e.preventDefault();
                props.trigger = button.getAttribute('data-trigger-zoom');
                props.open = true;
            });
        });

        fns.forEach(fn => fn());
        window.addEventListener('resize.end', () => fns.forEach(fn => fn()));
    }
});
