import Vue from 'vue';
import { docReady, qsa } from "@js/libs/helpers";

import "@js/vue-setup/register-base";
import "@js/vue-setup/register-history";
import { store } from "@js/vue-setup/register-store";

const components = {
	ArtworkFilters: () => import("@js/components/ArtworkFilters.vue"),
	ArtworkList: () => import("@js/components/ArtworkList.vue"),
	HomepageTimeline: () => import("@js/components/HomepageTimeline.vue"),
};

// If [data-vue] exists then the import function above will 
// execute. but if it doesn't, it won't. With the import being
// a function, webpack will split it into a partial js file,
// and any CSS files will be split out too. So, if the function
// never runs, the CSS and JS are never loaded. It's an on
// demand thing. 

// You are responsible then for adding a loading state, and also
// to AVOID this pattern if the execution is something that happens
// on a hide/show and should feel faster, eg, what if the connection
// went down? It's not the best idea to use it everywhere-all-the-time
// but could work well for a filtering system on a news section, or
// for search functionality and so on. 

docReady(() => {
	VuexPreload.forEach( ({ action, data }) => {
		store.dispatch( action, data );
	});

	const ogResults = document.getElementById('original-results');
	if ( ogResults )  {
		store.subscribe( ({ type, payload }) => {
			if ( type === 'categories/activate' ) {
				if ( payload ) {
					ogResults.style.display = 'none';
				}
			}
		});
	}

	qsa('[data-vue]', el => {
		const c = el.getAttribute('data-vue');

		if ( typeof components[c] === "function" ) {
				return new Vue({
					el,
					store,
					render: h => h(components[c])
				});		
		} else {
			console.log( `${c} was not imported. Did you forget to register this component?`);
		}
	});
});